/**
 * The higher order component responsible for controlling the uic code and submitting the form.
 */
import React, { Component } from 'react';
import { PropTypes, func } from 'prop-types';
import {
  NAME_INPUT, NUMERO_INPUT, DEFAULT_TIMEOUT, DEFAULT_ENDPOINT,
  URL_CHECK_DISPONIBILITY,
} from 'services/consts';
import { validateEmptyValue } from 'services/validators';
import axios from 'axios';

export default function hoc(Template) {
  const Steps = class Steps extends Component {
    // eslint-disable-next-line react/sort-comp
    constructor(props) {
      super(props);
      this.state = {
        penaltyType: '',
        name: '',
        numero: '',
        token: '',
        step: props.step,
        tickets: props.tickets,
        amount: 0,
        nbTicketCheck: 0,
        errors: [],
        errorWS: {},
        penaltyError: false,
        nameError: false,
        numeroError: false,
        endpoint: DEFAULT_ENDPOINT,
        fetchedEndpoint: false,
      };
      // should we attempt to check for site availability
      // since both setState and axios are asynchronous
      axios.get(this.state.endpoint + URL_CHECK_DISPONIBILITY).then(({ data }) => {
        if (data && !data.appAvailable) {
          // site is down, redirect user
          window.location.replace('/site-indisponible');
        }
        this.setState({
          fetchedEndpoint: true,
        });
      });
      const langInit = sessionStorage.getItem('lang') ? sessionStorage.getItem('lang') : 'fr';
      props.handleLang(langInit);
      if (
        props.error
      ) {
        this.hasError = true;
      } else {
        this.shouldFetch = true;
      }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.errorWS !== prevState.errorWS) {
        return {
          errorWS: nextProps.errorWS,
          isLoading: nextProps.isLoading,
        };
      }
      if (nextProps.tickets !== prevState.tickets) {
        let checkList = [];
        let step = 1;
        let { amount } = prevState;
        const nbTicketCheck = nextProps.tickets.length;
        if (nextProps.tickets.length > 0) {
          checkList = nextProps.tickets;
          checkList.forEach((t, index) => {
            checkList[index].checked = true;
            checkList[index].newAmount = t.amount;
            amount += t.amount;
          });
          step = 2;
        }
        return {
          tickets: checkList, step, amount, nbTicketCheck,
        };
      }
      return null;
    }

    static amountChange(tickets) {
      let amount = 0;
      tickets.forEach((t, index) => {
        if (tickets[index].checked) {
          amount += t.newAmount;
        }
      });
      return amount;
    }

    changeInputPenalty = (event) => {
      this.setState({ penaltyType: event.target.value });
    };

    changeInputName = (event) => {
      this.setState({ name: event.target.value });
    };

    changeInputNumero = (event) => {
      this.setState({ numero: event.target.value.replace(/\D/, '') });
    };

    changeInputEmail = (event) => {
      this.setState({ email: event.target.value });
    };

    changeStepPage = (step) => {
      this.setState({ isSearchLoading: false });
      this.setState({ step });
      this.props.setStep(this.state.step);
    };

    onCkeckAmount = (event) => {
      const { tickets } = this.state;
      let { amount, nbTicketCheck } = this.state;
      const idCheck = event.target.id;
      const i = idCheck.indexOf('-') + 1;
      const index = idCheck.substr(i, idCheck.length - 1);
      if (event.target.checked) {
        tickets[index].checked = true;
        nbTicketCheck += 1;
        amount += tickets[index].amount;
        this.setState({ tickets, amount, nbTicketCheck });
      } else {
        tickets[index].checked = false;
        nbTicketCheck -= 1;
        amount -= tickets[index].amount;
        this.setState({ tickets, amount, nbTicketCheck });
      }
    };

    onCkeckAmountAll = (event) => {
      const { tickets } = this.state;
      let { nbTicketCheck } = this.state;
      if (event.target.checked) {
        for (let i = 0; i < tickets.length; i += 1) {
          tickets[i].checked = true;
        }
        nbTicketCheck = tickets.length;
      } else {
        for (let i = 0; i < tickets.length; i += 1) {
          tickets[i].checked = false;
        }
        nbTicketCheck = 0;
      }
      const newAmount = Steps.amountChange(tickets);
      this.setState({ tickets, nbTicketCheck, amount: newAmount });
    };

    onChangeNewAmount = (event) => {
      const { tickets } = this.state;
      const idCheck = event.target.id;
      const i = idCheck.indexOf('-') + 1;
      const index = idCheck.substr(i, idCheck.length - 1);
      tickets[index].newAmount = parseFloat(event.target.value.replace(',', '.'));
      this.setState({ tickets });
    };

    goBack = (event) => {
      event.preventDefault();
      this.changeStepPage(1);
      this.props.resetTicket();
      this.setState({
        errors: [],
        amount: 0,
        nbTicketCheck: 0,
      });
    };

    clearErrors = () => {
      if (this.props.errorWS !== null) {
        this.props.clearError();
      }
    };

    validate = () => {
      // On nettoie les potentielles erreurs.
      this.clearErrors();
      sessionStorage.removeItem('errorPayment');
      sessionStorage.removeItem('token.client.expired');
      const errors = [];
      let nameError = false;
      let numeroError = false;

      // Si le champ name n'est pas renseigné.
      validateEmptyValue(
        this.state.name,
        errors,
        'srt.error.name.empty',
        NAME_INPUT,
      );

      // Si le champ numéro n'est pas renseigné.
      validateEmptyValue(
        this.state.numero,
        errors,
        'srt.error.numero.empty',
        NUMERO_INPUT,
      );

      // Si des erreurs ont été levées on propage l'info.
      if (errors.length > 0) {
        errors.forEach((e) => {
          if (e.fieldError === 'name-input') {
            nameError = true;
          }
          if (e.fieldError === 'numero-input') {
            numeroError = true;
          }
          return (nameError, numeroError);
        });
        this.setState({
          errors, nameError, numeroError,
        });
        return false;
      }
      this.setState({
        errors, nameError, numeroError,
      });
      return true;
    };

    validation = () => {
      this.setState({ isSearchLoading: true });
      setTimeout(() => {
        this.setState(() => ({
          isSearchLoading: false,
        }));
      }, 1500);
      if (this.validate()) {
        this.props.setName(this.state.name);
        this.props.setNumero(this.state.numero);
        if (this.props.fetch) {
          this.props.fetch(
            'PV',
            this.state.name,
            this.state.numero,
            this.state.endpoint,
            DEFAULT_TIMEOUT,
          );
        }
      }
    };

    validation2 = () => {
      if (this.state.nbTicketCheck > 0) {
        this.setState({ isLoading: true });
        const ticketsCheck = [];
        this.state.tickets.forEach((t) => {
          if (t.checked) {
            ticketsCheck.push(
              {
                ticketId: t.ticketId,
                comment: t.comment,
                amount: t.newAmount,
              },
            );
          }
        });
        this.props.fetchCheckout(
          ticketsCheck,
          this.state.email,
          this.props.lang,
          this.state.endpoint,
          DEFAULT_TIMEOUT,
        );
      }
    };

    submit = (event) => {
      event.preventDefault();
      const promises = [];
      Promise.all(promises).then(this.validation);
    };

    submit2 = (event) => {
      event.preventDefault();
      const promises = [];
      Promise.all(promises).then(this.validation2);
    };

    fetchWithToken = (token) => {
      // once the state is set, we pass the callback
      // and check if we have a token in the URL
      // if the token exists then we send it to the server
      // for validation
      this.setState({ token }, () => {
        if (this.state.token && this.state.token.length) {
          // if we have token then we pass to step 2
          if (this.props.fetchWithToken) {
            // function declared in dispatchTools.js as fetchToolsWithToken
            this.props.fetchWithToken(token, this.state.endpoint, this.state.DEFAULT_TIMEOUT);
          }
        }
      });
    };

    render() {
      const styleInit = sessionStorage.getItem('style') ? sessionStorage.getItem('style') : 'default';
      this.props.handleStyle(styleInit);
      return (
        <Template
          {...this.props}
          fetchWithToken={this.fetchWithToken}
          onSubmit={this.submit}
          onSubmit2={this.submit2}
          changeInputPenalty={this.changeInputPenalty}
          changeInputName={this.changeInputName}
          changeInputNumero={this.changeInputNumero}
          changeInputEmail={this.changeInputEmail}
          intl={this.props.intl}
          state={this.state}
          onCkeckAmount={this.onCkeckAmount}
          onCkeckAmountAll={this.onCkeckAmountAll}
          goBack={this.goBack}
          onChangeNewAmount={this.onChangeNewAmount}
        />
      );
    }
  };

  Steps.propTypes = {
    intl: PropTypes.shape({ formatMessage: func }).isRequired,
    error: PropTypes.shape(),
    errorWS: PropTypes.shape(),
    isLoading: PropTypes.shape(),
    clearError: PropTypes.func.isRequired,
    setPenalty: PropTypes.func.isRequired,
    setName: PropTypes.func.isRequired,
    setNumero: PropTypes.func.isRequired,
    setStep: PropTypes.func.isRequired,
    fetchWithToken: PropTypes.func,
    fetch: PropTypes.func.isRequired,
    fetchCheckout: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    tickets: PropTypes.arrayOf().isRequired,
    resetTicket: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    handleLang: PropTypes.func.isRequired,
    handleStyle: PropTypes.func.isRequired,
  };

  Steps.defaultProps = {
    error: {},
    errorWS: {},
    isLoading: false,
    fetchWithToken: () => { },
  };

  return Steps;
}
