import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import AccessibilityBar from 'components/Header/Accessibility/AccessibilityBar';
import logo from 'assets/images/logo-srtpf-header.jpg';


class Header extends Component {
  static deleteToken() {
    sessionStorage.removeItem('token.agent');
    document.location.reload(true);
  }

  render() {
    return (
      <header
        role="banner"
        id="header"
        className="snc-header-site"
      >
        <AccessibilityBar
          lang={this.props.lang}
          handleLang={this.props.handleLang}
          handleStyle={this.props.handleStyle}
        />
        <div className="header-for-sticky snc-header-sticky">
          <div className="main-header">
            <div className="container grid">
              <div className="clearfix">
                <a href="/" className="navigation-logo-link">
                  <img
                    src={logo}
                    className="navigation-logo"
                    alt="Service de Recouvrement du Transport Public Ferroviaire  - Page d'accueil"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  lang: PropTypes.string.isRequired,
  handleLang: PropTypes.func.isRequired,
  handleStyle: PropTypes.func.isRequired,
};

export default Header;
