import {
  SET_PENALTY,
  SET_NAME,
  SET_NUMERO,
  FETCH_AMOUNT,
  RECEIVE_AMOUNT,
  RECEIVE_AMOUNT_FAILURE,
  SET_STEP,
  RESET_TICKET,
  CLEAR_ERROR,
  RECEIVE_AMOUNT_WITH_TOKEN,
  FETCH_AMOUNT_WITH_TOKEN,
  RECEIVE_AMOUNT_WITH_TOKEN_FAILURE,
} from '../actions/ActionTypes';

const initialState = {
  name: '',
  numero: null,
  tickets: [],
  isFetching: false,
  isLoading: false,
  fetched: false,
  fetchingAmount: false,
  step: 1,
  errorWS: null,
};

// eslint-disable-next-line default-param-last
export default function stepsReducer(prev = initialState, action) {
  let state;
  switch (action.type) {
    case SET_PENALTY: {
      state = {
        ...prev,
      };
      break;
    }
    case SET_NAME: {
      state = {
        ...prev,
      };
      break;
    }
    case SET_NUMERO: {
      state = {
        ...prev,
      };
      break;
    }
    case SET_STEP: {
      state = {
        ...prev,
        step: action.res,
      };
      break;
    }
    case RESET_TICKET: {
      state = {
        ...prev,
        tickets: [],
        step: 1,
      };
      break;
    }
    case RECEIVE_AMOUNT: {
      state = {
        ...prev,
        fetchingAmount: false,
        isFetching: false,
        isLoading: false,
        fetched: true,
        tickets: action.res,
        errorWS: action.msgError,
        step: 2,
      };
      break;
    }
    case RECEIVE_AMOUNT_WITH_TOKEN: {
      state = {
        ...prev,
        fetchingAmount: false,
        isFetching: false,
        isLoading: false,
        fetched: true,
        tickets: action.res,
        errorWS: action.msgError,
        step: 2,
      };
      break;
    }
    case RECEIVE_AMOUNT_WITH_TOKEN_FAILURE: {
      const actionError = action.error;
      let errorPartner = false;
      let errorWs = null;
      if (actionError.response.body !== undefined) {
        // successful http response: HTTP_OK
        errorWs = actionError.response.body.error;
        errorPartner = errorWs.partnerError || false;
      } else {
        // code error
        /*
         * server can send back two types of JSON
         * 1. error code and label are contained inside the response within an error object
         * (such is the case of PenaltyError responses)
         *   {"body" : {"error": {"code": "status code", "label": "status value"}}}
         *
         * 2. error code and label are directly children of body
         * (such is the case of GlobalAvailability responses
         *   {"body" : {"code": "status code", "label": "status value"}}
         *
         * so we need to check if the body has an error object or not
        */
        errorWs = JSON.parse(actionError.message);
        errorPartner = errorWs.partnerError || false;
        // check the kind of error we have
        errorWs = errorWs.error !== undefined ? errorWs.error : errorWs;
      }
      // add isPartner info to errorWs
      errorWs = {
        ...errorWs,
        isPartnerError: errorPartner,
      };

      state = {
        ...prev,
        fetchingAmount: false,
        isFetching: false,
        isLoading: false,
        fetched: true,
        tickets: [],
        errorWS: errorWs,
        step: 2,
      };
      break;
    }
    case FETCH_AMOUNT: {
      state = {
        ...prev,
        isLoading: true,
        fetchingAmount: true,
        errorWS: null,
      };
      break;
    }
    case FETCH_AMOUNT_WITH_TOKEN: {
      state = {
        ...prev,
        isLoading: true,
        fetchingAmount: true,
        errorWs: null,
      };
      break;
    }
    case RECEIVE_AMOUNT_FAILURE: {
      const actionError = action.error;
      let errorPartner = false;
      let errorWs = null;
      if (actionError.response.body !== undefined) {
        // successful http response: HTTP_OK
        errorWs = actionError.response.body.error;
        errorPartner = errorWs.partner || false;
      } else {
        // code error
        /*
         * server can send back two types of JSON
         * 1. error code and label are contained inside the response within an error object
         * (such is the case of PenaltyError responses)
         *   {"body" : {"error": {"code": "status code", "label": "status value"}}}
         *
         * 2. error code and label are directly children of body
         * (such is the case of GlobalAvailability responses
         *   {"body" : {"code": "status code", "label": "status value"}}
         *
         * so we need to check if the body has an error object or not
        */
        errorWs = JSON.parse(actionError.message);
        errorPartner = errorWs.partner || false;
        // check the kind of error we have
        errorWs = errorWs.error !== undefined ? errorWs.error : errorWs;
      }
      // add isPartner info to errorWs
      errorWs = {
        ...errorWs,
        isPartnerError: errorPartner,
      };

      state = {
        ...prev,
        isFetching: false,
        isLoading: false,
        fetched: false,
        errorWS: errorWs,
        step: prev.step,
        tickets: [],
      };
      break;
    }
    case CLEAR_ERROR: {
      state = {
        ...prev,
        errorWS: null,
      };
      break;
    }
    default:
      state = prev;
  }
  return state;
}
