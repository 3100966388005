/**
 * Action creator generator used for departures and arrivals.
 */
import ws from 'services/ws';
import {
  SET_STEP, FETCH_AMOUNT, RECEIVE_AMOUNT, RECEIVE_AMOUNT_FAILURE, RESET_TICKET,
  SET_NAME, SET_NUMERO, SET_PENALTY, FETCH_AMOUNT_WITH_TOKEN, RECEIVE_AMOUNT_WITH_TOKEN,
  RECEIVE_AMOUNT_WITH_TOKEN_FAILURE,
} from './ActionTypes';

export const requestAmount = (
  penalty,
  name,
  numero,
) => ({
  type: FETCH_AMOUNT,
  penalty,
  name,
  numero,
});

export const requestAmountWithToken = (token) => ({
  type: FETCH_AMOUNT_WITH_TOKEN,
  token,
});

export const receiveAmount = (
  penalty,
  name,
  numero,
  res,
  msgError,
) => ({
  type: RECEIVE_AMOUNT,
  penalty,
  name,
  numero,
  res,
  msgError,
});

export const receiveAmountWithToken = (token, res, msgError) => ({
  type: RECEIVE_AMOUNT_WITH_TOKEN,
  token,
  res,
  msgError,
});

export const receiveFailureAmount = (error) => ({
  type: RECEIVE_AMOUNT_FAILURE,
  error,
  isPartnerError: error.partner || false,
});

export const receiveAmountWithTokenFailure = (error) => ({
  type: RECEIVE_AMOUNT_WITH_TOKEN_FAILURE,
  error,
  isPartnerError: error.partner || false,
});

export const fetchAmount = (
  penalty,
  name,
  numero,
  dispatch,
  endpoint,
  timeout,
) => {
  requestAmount(
    penalty,
    name,
    numero,
  );
  ws(endpoint, timeout).retrieveDueAmount(
    penalty,
    name,
    numero,
  ).then(({ res, msgError, authorization }) => {
    sessionStorage.setItem('token.client', authorization);
    dispatch(receiveAmount(
      penalty,
      name,
      numero,
      res,
      msgError,
    ));
  }, (error) => {
    dispatch(receiveFailureAmount(error));
  });
};

export const fetchAmountWithToken = (token, dispatch, endpoint, timeout) => {
  requestAmountWithToken(token);
  ws(endpoint, timeout)
    .retrieveDueAmountWithToken(token)
    .then(
      ({ res, msgError, authorization }) => {
        sessionStorage.setItem('token.client', authorization);

        return dispatch(receiveAmountWithToken(token, res, msgError));
      },
    )
    .catch((error) => {
      dispatch(receiveAmountWithTokenFailure(error));
    });
};

export const requestCheckoutAmount = (
  penalty,
  name,
  numero,
) => ({
  type: FETCH_AMOUNT,
  penalty,
  name,
  numero,
});

export const checkoutFailureAmount = (error) => ({
  type: RECEIVE_AMOUNT_FAILURE,
  error,
});

export const fetchCheckoutAmount = (
  tickets,
  email,
  lang,
  dispatch,
  endpoint,
  timeout,
) => {
  requestCheckoutAmount(
    tickets,
    email,
    lang,
  );
  ws(endpoint, timeout).checkoutDueAmount(
    email,
    lang,
    tickets,
  ).then(({ res }) => {
    window.location.replace(res);
  }, (error) => {
    // check if client token is expired here
    const parseError = error.response.body;
    if (parseError
      && parseError.error.code === 401
      && parseError.error.label === 'UNAUTHORIZED'
    ) {
      // client token expired
      sessionStorage.setItem('token.client.expired', 'Session expirée');
      window.location.replace('/');
    } else {
      // token not expired, different error
      dispatch(checkoutFailureAmount(error));
    }
  });
};

export const setPenalty = (
  penalty = '',
) => ({
  type: SET_PENALTY,
  penalty,
});

export const setName = (
  name = '',
) => ({
  type: SET_NAME,
  name,
});

export const setNumero = (
  numero = '',
) => ({
  type: SET_NUMERO,
  numero,
});

export const setStep = (
  step = '',
) => ({
  type: SET_STEP,
  step,
});

export const resetTicket = () => ({
  type: RESET_TICKET,
});
