/**
 * The connected component of Step1.
 */
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Step1 from 'pages/step/Steps/Steps';
import hoc from 'pages/step/Steps/StepsHOC';
import {
  clearError, fetchTools, setNameTools, setNumeroTools,
  setStepTools, setPenaltyTools,
  resetTicketTools, fetchCheckoutTools, fetchToolsWithToken,
} from 'pages/step/Steps/dispatchTools';
import init from 'services/init';


function mapStateToProps(state, props) {
  const initialStep = props.step !== undefined ? props.step : init(() => state.steps.step, 1);

  return {
    lang: props.lang,
    handleLang: props.handleLang,
    handleStyle: props.handleStyle,
    error: init(() => state.steps.error, null),
    step: initialStep,
    isLoading: init(() => state.steps.isLoading, false),
    tickets: init(() => state.steps.tickets, []),
    errorWS: init(() => state.steps.errorWS, null),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearError: clearError(dispatch),
    fetch: fetchTools(dispatch),
    fetchWithToken: fetchToolsWithToken(dispatch),
    fetchCheckout: fetchCheckoutTools(dispatch),
    setPenalty: setPenaltyTools(dispatch),
    setName: setNameTools(dispatch),
    setNumero: setNumeroTools(dispatch),
    setStep: setStepTools(dispatch),
    resetTicket: resetTicketTools(dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(hoc(Step1)));
