/**
 * The connected component of Contact.
 */
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Contact from 'pages/information/Contact/Contact';
import hoc from 'pages/information/Contact/ContactHOC';


function mapStateToProps(state, props) {
  return {
    lang: props.lang,
    handleLang: props.handleLang,
    handleStyle: props.handleStyle,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(hoc(Contact)));
