import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import commonProptypes from 'utils/commonProptypes';
import { PropTypes, string } from 'prop-types';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import storeIndex from 'reducers/index';
import ConnexionInterneProps from 'pages/step/ConnexionInterne/ConnexionInterneProps';
import PaymentCheckProps from 'pages/step/PaymentCheck/PaymentCheckProps';
import ResumeProps from 'pages/step/Resume/ResumeProps';
import ContactProps from 'pages/information/Contact/ContactProps';
import LegalInformationProps from 'pages/information/LegalInformation/LegalInformationProps';
import SecurityInformationProps from 'pages/information/SecurityInformation/SecurityInformationProps';
import PaymentMethodProps from 'pages/information/PaymentMethod/PaymentMethodProps';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import StepsProps from 'pages/step/Steps/StepsProps';
import Version from 'pages/version/Version';
import NotFound from 'pages/notfound/NotFound';
import SiteIndisponible from 'pages/indisponible/SiteIndisponible';

/* eslint-disable no-underscore-dangle */
const store = createStore(
  storeIndex,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);
/* eslint-enable */

const App = ({
  messages, lang, setStyle,
}) => {
  const [locale, setLocale] = useState(lang);
  if (!sessionStorage.getItem('style')) {
    sessionStorage.setItem('style', 'default');
  }

  const handleStyle = (newStyle) => {
    const prevStyle = sessionStorage.getItem('style');
    setStyle(prevStyle, newStyle);
    sessionStorage.setItem('style', newStyle);
  };

  const handleLang = (newLang) => {
    setLocale(newLang);
    sessionStorage.setItem('lang', newLang);
    document.documentElement.lang = newLang;
  };

  return (
    <div className="global-container">
      <Provider store={store}>
        <IntlProvider locale={locale} messages={messages[locale]}>
          <Router>
            <Routes>
              <Route
                exact
                path="/"
                element={(
                  <StepsProps
                    lang={locale}
                    handleLang={handleLang}
                    handleStyle={handleStyle}
                  />
                )}
              />
              <Route
                exact
                path="/direct/:token"
                element={(
                  <StepsProps
                    lang={locale}
                    handleLang={handleLang}
                    handleStyle={handleStyle}
                    step={2}
                  />
                )}
              />
              <Route
                exact
                path="/resume"
                element={(
                  <ResumeProps
                    lang={locale}
                    handleLang={handleLang}
                    handleStyle={handleStyle}
                  />
                )}
              />
              <Route
                exact
                path="/connexioninterne"
                element={(
                  <ConnexionInterneProps
                    lang={locale}
                    handleLang={handleLang}
                    handleStyle={handleStyle}
                  />
                )}
              />
              <Route path="/payment/check" element={<PaymentCheckProps />} />
              <Route
                path="/:locale/contact"
                element={(
                  <ContactProps
                    lang={locale}
                    handleLang={handleLang}
                    handleStyle={handleStyle}
                  />
                )}
              />
              <Route
                exact
                path="/:locale/mentions-legales"
                element={(
                  <LegalInformationProps
                    lang={locale}
                    handleLang={handleLang}
                    handleStyle={handleStyle}
                  />
                )}
              />
              <Route
                exact
                path="/:locale/infos-securite"
                element={(
                  <SecurityInformationProps
                    lang={locale}
                    handleLang={handleLang}
                    handleStyle={handleStyle}
                  />
                )}
              />
              <Route
                exact
                path="/:locale/dispositions-applicables"
                element={(
                  <PaymentMethodProps
                    lang={locale}
                    handleLang={handleLang}
                    handleStyle={handleStyle}
                  />
                )}
              />
              <Route path="/version" element={<Version />} />
              <Route
                path="/site-indisponible"
                element={(
                  <SiteIndisponible
                    lang={locale}
                    handleLang={handleLang}
                    handleStyle={handleStyle}
                  />
                )}
              />
              <Route
                path="/404"
                element={(
                  <NotFound
                    lang={locale}
                    handleLang={handleLang}
                    handleStyle={handleStyle}
                  />
                )}
              />
              <Route path="*" element={<Navigate to="/404" />} />
            </Routes>
          </Router>
        </IntlProvider>
      </Provider>
    </div>
  );
};

App.propTypes = {
  messages: commonProptypes.messages.isRequired,
  lang: string.isRequired,
  setStyle: PropTypes.func.isRequired,
};

export default App;
