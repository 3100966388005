/**
 * The higher order component responsible for controlling the uic code and submitting the form.
 */
import React, { Component } from 'react';
import { PropTypes, func } from 'prop-types';

export default function hoc(Template) {
  const LegalInformation = class LegalInformation extends Component {
    constructor(props) {
      super(props);
      let langInit = 'fr';
      langInit = sessionStorage.getItem('lang') ? sessionStorage.getItem('lang') : window.location.pathname.substring(1, 3);
      props.handleLang(langInit);
      if (
        props.error
      ) {
        this.hasError = true;
      } else {
        this.shouldFetch = true;
      }
    }

    render() {
      const styleInit = sessionStorage.getItem('style') ? sessionStorage.getItem('style') : 'default';
      this.props.handleStyle(styleInit);
      return (
        <Template
          {...this.props}
          intl={this.props.intl}
        />
      );
    }
  };

  LegalInformation.propTypes = {
    intl: PropTypes.shape({ formatMessage: func }).isRequired,
    error: PropTypes.shape(),
    clearError: PropTypes.func.isRequired,
    setCodeAgent: PropTypes.func.isRequired,
    setPassword: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    handleLang: PropTypes.func.isRequired,
    handleStyle: PropTypes.func.isRequired,
  };

  LegalInformation.defaultProps = {
    error: {},
  };

  return LegalInformation;
}
