import React from 'react';
import { injectIntl } from 'react-intl';
import { PropTypes, func } from 'prop-types';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
// import { Link } from 'react-router-dom';

const Contact = ({
  intl, lang, handleLang, handleStyle,
}) => (
  <>
    <Header lang={lang} handleLang={handleLang} handleStyle={handleStyle} />
    <main role="main" id="contact-content" className="contact-content">
      <section className="container" style={{ marginTop: '70px' }}>
        <div className="main-div">
          <div className="block-information-grey">
            <p>
              <span className="title-information-block">
                { intl.formatMessage({ id: 'contact.title' }) }
              </span>
            </p>
            <div className="text-information-white">
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'contact.content' }) }}
              />
            </div>
          </div>
        </div>
      </section>
    </main>
    <Footer lang={lang} handleLang={handleLang} />
  </>
);

Contact.propTypes = {
  intl: PropTypes.shape({ formatMessage: func }).isRequired,
  lang: PropTypes.string.isRequired,
  handleLang: PropTypes.func.isRequired,
  handleStyle: PropTypes.func.isRequired,
};

Contact.defaultProps = {};

export default injectIntl(Contact);
