/**
 * The connected component of LegalInformation.
 */
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import LegalInformation from 'pages/information/LegalInformation/LegalInformation';
import hoc from 'pages/information/LegalInformation/LegalInformationHOC';


function mapStateToProps(state, props) {
  return {
    lang: props.lang,
    handleLang: props.handleLang,
    handleStyle: props.handleStyle,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(hoc(LegalInformation)));
