import React from 'react';
import { injectIntl } from 'react-intl';
import { PropTypes, func } from 'prop-types';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import HeaderBlock from 'components/HeaderBlock/HeaderBlock';
import formatAmount from 'utils/formatAmount';

const formatDateTime = (intl, transaction) => intl.formatMessage({ id: 'srt.resume.transaction.dateTime' }, { date: transaction.transactionDate, time: transaction.transactionTime });

const Resume = ({
  intl, transaction, lang, handleLang, handleStyle, onClickFinish,
}) => (
  <>
    <Header lang={lang} handleLang={handleLang} handleStyle={handleStyle} />
    <main role="main" id="main-content" className="main-content">
      <section className="container" style={{ marginTop: '70px' }}>
        <div className="main-div">
          <HeaderBlock intl={intl} step={4} />
          <h2 className="subtitle-step-name-num">
            {transaction.state === 'TRANSACTION'
              ? intl.formatMessage({ id: 'srt.resume.subtitle' })
              : intl.formatMessage({ id: 'srt.resume.subtitle.abandon' })}
          </h2>

          <div className="srt-transaction-summary">
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <p className="srt-transaction-summary-label">{intl.formatMessage({ id: 'srt.resume.transaction.id' })}</p>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                <p className="srt-transaction-summary-value">
                  {transaction.orderId}
                  /
                  {transaction.transactionId}
                </p>
              </div>
            </div>
            {transaction.transactionNumber
              ? (
                <div className="row center-xs">
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <p className="srt-transaction-summary-label">{intl.formatMessage({ id: 'srt.resume.transaction.number' })}</p>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                    <p className="srt-transaction-summary-value">{transaction.transactionNumber}</p>
                  </div>
                </div>
              ) : null}
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <p className="srt-transaction-summary-label">{intl.formatMessage({ id: 'srt.resume.card_number' })}</p>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                <p className="srt-transaction-summary-value">{transaction.cardNumber}</p>
              </div>
            </div>
            {transaction.authorizationNumber
              ? (
                <div className="row center-xs">
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <p className="srt-transaction-summary-label">{intl.formatMessage({ id: 'srt.resume.authorization_number' })}</p>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                    <p className="srt-transaction-summary-value">{transaction.authorizationNumber}</p>
                  </div>
                </div>
              )
              : null}
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <p className="srt-transaction-summary-label">{intl.formatMessage({ id: 'srt.resume.market_id' })}</p>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                <p className="srt-transaction-summary-value">{transaction.merchantId}</p>
              </div>
            </div>
            {transaction.certificate
              ? (
                <div className="row center-xs">
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <p className="srt-transaction-summary-label">{intl.formatMessage({ id: 'srt.resume.transaction.certificate' })}</p>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                    <p className="srt-transaction-summary-value">{transaction.certificate}</p>
                  </div>
                </div>
              )
              : null}
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <p className="srt-transaction-summary-label">{intl.formatMessage({ id: 'srt.resume.date_time' })}</p>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                <p className="srt-transaction-summary-value">{formatDateTime(intl, transaction)}</p>
              </div>
            </div>
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <p className="srt-transaction-summary-label">{intl.formatMessage({ id: 'srt.resume.amount' })}</p>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                <p className="srt-transaction-summary-value">{formatAmount(transaction.totalAmount)}</p>
              </div>
            </div>
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <p className="srt-transaction-summary-label">{intl.formatMessage({ id: 'srt.resume.transaction.type' })}</p>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                <p className="srt-transaction-summary-value">{intl.formatMessage({ id: 'srt.resume.transaction.type.value' })}</p>
              </div>
            </div>
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <p className="srt-transaction-summary-label">{intl.formatMessage({ id: 'srt.resume.brand' })}</p>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                <p className="srt-transaction-summary-value">{transaction.cardBrand}</p>
              </div>
            </div>
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <p className="srt-transaction-summary-label">{intl.formatMessage({ id: 'srt.resume.use' })}</p>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                <p className="srt-transaction-summary-value">{intl.formatMessage({ id: 'srt.resume.use.value' })}</p>
              </div>
            </div>
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <p className="srt-transaction-summary-label">{intl.formatMessage({ id: 'srt.resume.url' })}</p>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                <p className="srt-transaction-summary-value">{intl.formatMessage({ id: 'srt.resume.url.value' })}</p>
              </div>
            </div>
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <p className="srt-transaction-summary-label">{intl.formatMessage({ id: 'srt.resume.state' })}</p>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                {transaction.state === 'TRANSACTION'
                  ? (
                    <p className="srt-transaction-summary-value">
                      {intl.formatMessage({ id: 'srt.resume.state.value.paiement' })}
                    </p>
                  )
                  : null}
                {transaction.state === 'ABANDON'
                  ? (
                    <p className="srt-transaction-summary-value">
                      {intl.formatMessage({ id: 'srt.resume.state.value.abandon' })}
                    </p>
                  )
                  : null}
              </div>
            </div>
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <p className="srt-transaction-summary-label">{intl.formatMessage({ id: 'srt.resume.type' })}</p>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4">
                <p className="srt-transaction-summary-value">{transaction.transactionType}</p>
              </div>
            </div>
          </div>

          {/* The table in it's desktop version */}
          {transaction.penaltyInformationsList
          && (
          <div className="srt-transaction-summary-table-large">
            <div role="table" aria-labelledby="ticket-table-title" className="srt-table">
              <div role="rowgroup">
                <div role="row" className="srt-table-row srt-table-row-header">
                  <span role="columnheader" className="srt-table-cell srt-table-cell-header">{intl.formatMessage({ id: 'srt.resume.name' })}</span>
                  <span role="columnheader" className="srt-table-cell srt-table-cell-header">{intl.formatMessage({ id: 'srt.resume.num' })}</span>
                  <span role="columnheader" className="srt-table-cell srt-table-cell-header">{intl.formatMessage({ id: 'srt.resume.amount' })}</span>
                  <span role="columnheader" className="srt-table-cell srt-table-cell-header">{intl.formatMessage({ id: 'srt.resume.comment' })}</span>
                </div>
              </div>

              <div role="rowgroup" className="srt-table-content">
                {transaction.penaltyInformationsList
                && transaction.penaltyInformationsList.map((t) => (
                  <div role="row" className="srt-table-row" key={t.id}>
                    <span role="cell" className="srt-table-cell">
                      {transaction.name}
                    </span>
                    <span role="cell" className="srt-table-cell">
                      {t.identifiant1}
                    </span>
                    <span role="cell" className="srt-table-cell">
                      {formatAmount(t.amount)}
                    </span>
                    <span role="cell" className="srt-table-cell">
                      {t.typeIdentifiant1}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          )}
          {/* End of the table in it's desktop version */}

          {/* The table in it's mobile version */}
          <div className="srt-transaction-summary-table-small">
            <ul className="srt-ticket-list">
              <li className="srt-ticket-item">
                <div role="table" aria-labelledby="ticket-table-title" className="srt-table">
                  {transaction.penaltyInformationsList
                  && transaction.penaltyInformationsList.map((t) => (
                    <div role="rowgroup" key={t.id}>
                      <div role="row" className="srt-table-row">
                        <span role="rowheader" className="srt-table-cell srt-table-cell-header">{intl.formatMessage({ id: 'srt.resume.name' })}</span>
                        <span role="cell" className="srt-table-cell">{transaction.name}</span>
                      </div>
                      <div role="row" className="srt-table-row">
                        <span role="columnheader" className="srt-table-cell srt-table-cell-header">{intl.formatMessage({ id: 'srt.resume.num' })}</span>
                        <span role="cell" className="srt-table-cell">{t.identifiant1}</span>
                      </div>
                      <div role="row" className="srt-table-row">
                        <span role="columnheader" className="srt-table-cell srt-table-cell-header">{intl.formatMessage({ id: 'srt.resume.amount' })}</span>
                        <span role="cell" className="srt-table-cell">{formatAmount(t.amount)}</span>
                      </div>
                      <div role="row" className="srt-table-row">
                        <span role="columnheader" className="srt-table-cell srt-table-cell-header">{intl.formatMessage({ id: 'srt.resume.comment' })}</span>
                        <span role="cell" className="srt-table-cell">{t.typeIdentifiant1}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </li>
            </ul>
          </div>
          {/* End of the table in it's mobile version */}
          {/** Bouton PDF mise en commnentaire pour une possible réutilisation
              <button type="button" className="srt-transaction-summary-link" target="_blank"
                title="récapitulatif - nouvelle fenêtre">
                {intl.formatMessage({ id: 'srt.resume.download' })}
                <span aria-hidden="true"
                  className="snc-icon-download srt-transaction-summary-link-icon" />
              </button>
            */}
          <div className="btn-wrapper">
            <button type="button" className="btn btn-default btn-block btn-srt-end" title="Terminer" onClick={onClickFinish}>
              {intl.formatMessage({ id: 'srt.resume.finish' })}
            </button>
          </div>
        </div>
      </section>
    </main>
    <Footer lang={lang} handleLang={handleLang} />
  </>
);

// TODO mettre transaction et tickets en required et supprimer les defaultProps
Resume.propTypes = {
  intl: PropTypes.shape({ formatMessage: func }).isRequired,
  lang: PropTypes.string.isRequired,
  handleLang: PropTypes.func.isRequired,
  handleStyle: PropTypes.func.isRequired,
  transaction: PropTypes.shape(),
  onClickFinish: PropTypes.func.isRequired,
};

Resume.defaultProps = {
  transaction: {
    transactionId: 'XXXXXXXXX',
    transactionDate: 'XXXXXXXXX',
    transactionTime: 'XXXXXXXXX',
    transactionType: 'XXXXXXXXX',
    cardNumber: 'XXXXXXXXX',
    merchantId: 'XXXXXXXXX',
    totalAmount: 0,
    name: 'XXXXXXXXX',
    authorizationNumber: 'XXXXXXXXX',
    penaltyInformationsList: [
      {
        identifiant1: 'XXXXXXXXX',
        typeIdentifiant1: 'XXXXXXXXX',
        identifiant2: 'XXXXXXXXX',
        typeIdentifiant2: 'XXXXXXXXX',
        identifiant3: 'XXXXXXXXX',
        typeIdentifiant3: 'XXXXXXXXX',
        amount: 0,
      },
    ],
  },
};

export default injectIntl(Resume);
