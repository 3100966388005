/**
 * The higher order component responsible for controlling the uic code and submitting the form.
 */
import React, { Component } from 'react';
import { PropTypes, func } from 'prop-types';
import { validateEmptyValue } from 'services/validators';
import {
  CODE_AGENT_INPUT, PASSWORD_INPUT, DEFAULT_TIMEOUT, DEFAULT_ENDPOINT,
} from 'services/consts';

export default function hoc(Template) {
  const ConnexionInterne = class ConnexionInterne extends Component {
    constructor(props) {
      super(props);
      this.state = {
        codeagent: '',
        password: '',
        errors: [],
        codeAgentError: false,
        passwordError: false,
        endpoint: DEFAULT_ENDPOINT,
      };
      const langInit = sessionStorage.getItem('lang') ? sessionStorage.getItem('lang') : 'fr';
      props.handleLang(langInit);
      if (
        props.error
      ) {
        this.hasError = true;
      } else {
        this.shouldFetch = true;
      }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.error !== prevState.errors) {
        return {
          errorWS: nextProps.error,
        };
      }
      return null;
    }

    changeInputCodeAgent = (event) => {
      this.setState({ codeagent: event.target.value.trim() });
    };

    changeInputPassword = (event) => {
      this.setState({ password: event.target.value.trim() });
    };

    clearErrors = () => {
      if (this.props.error !== null) {
        this.props.clearError();
      }
    };

    validate = () => {
      // On nettoie les potentielles erreurs.
      this.clearErrors();

      const errors = [];
      const codeAgentError = false;
      const passwordError = false;

      // Si le champ lieu de départ n'est pas renseigné.
      validateEmptyValue(
        this.state.codeagent,
        errors,
        'srt.error.codeagent.empty',
        CODE_AGENT_INPUT,
      );

      // Si le champ lieu d'arrivée n'est pas renseigné.
      validateEmptyValue(
        this.state.password,
        errors,
        'srt.error.password.empty',
        PASSWORD_INPUT,
      );

      // Si des erreurs ont été levées on propage l'info.
      this.setState({ errors, codeAgentError, passwordError });
      return true;
    };

    validation = () => {
      if (this.validate()) {
        this.props.setCodeAgent(this.state.codeagent);
        this.props.setPassword(this.state.password);
        if (this.props.fetch) {
          this.props.fetch(
            this.state.codeagent,
            this.state.password,
            this.state.endpoint,
            DEFAULT_TIMEOUT,
          );
        }
      }
    };

    submit = (event) => {
      event.preventDefault();
      const promises = [];
      Promise.all(promises).then(this.validation);
    };

    render() {
      const styleInit = sessionStorage.getItem('style') ? sessionStorage.getItem('style') : 'default';
      this.props.handleStyle(styleInit);
      return (
        <Template
          {...this.props}
          onSubmit={this.submit}
          changeInputCodeAgent={this.changeInputCodeAgent}
          changeInputPassword={this.changeInputPassword}
          intl={this.props.intl}
          state={this.state}
          onCkeckAmount={this.onCkeckAmount}
          goBack={this.goBack}
        />
      );
    }
  };

  ConnexionInterne.propTypes = {
    intl: PropTypes.shape({ formatMessage: func }).isRequired,
    error: PropTypes.shape(),
    clearError: PropTypes.func.isRequired,
    setCodeAgent: PropTypes.func.isRequired,
    setPassword: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    handleLang: PropTypes.func.isRequired,
    handleStyle: PropTypes.func.isRequired,
  };

  ConnexionInterne.defaultProps = {
    error: {},
  };

  return ConnexionInterne;
}
