import React from 'react';
import { useParams } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { PropTypes, func } from 'prop-types';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import HeaderBlock from 'components/HeaderBlock/HeaderBlock';
import Step1 from 'components/Steps/Step1';
import Step2 from 'components/Steps/Step2';
// import { Link } from 'react-router-dom';

const Steps = ({
  intl, onSubmit, onSubmit2, state, changeInputPenalty, changeInputName, changeInputNumero,
  isLoading, onCkeckAmount, goBack, errorWS, onChangeNewAmount, changeInputEmail, lang, handleLang,
  handleStyle, onCkeckAmountAll, fetchWithToken,
}) => {
  // get path parameter and pass it down to Step2
  const { token } = useParams();

  return (
    <>
      <Header lang={lang} handleLang={handleLang} handleStyle={handleStyle} />
      <main role="main" id="main-content" className="main-content">
        <section className="container" style={{ marginTop: '70px' }}>
          <div className="main-div">
            <HeaderBlock intl={intl} step={state.step} />
            {isLoading
              && (
                <div>{intl.formatMessage({ id: 'step.loading' })}</div>
              )}
            {(state.tickets.length === 0 && token === undefined)
              && (
                <Step1
                  changeLoadedToken={fetchWithToken}
                  onSubmit={onSubmit}
                  state={state}
                  changeInputName={changeInputName}
                  changeInputNumero={changeInputNumero}
                  changeInputPenalty={changeInputPenalty}
                  errorWS={errorWS}
                />
              )}
            {(state.tickets.length > 0 || token !== undefined)
              && (
                <Step2
                  fetchWithToken={fetchWithToken}
                  token={token}
                  onSubmit2={onSubmit2}
                  state={state}
                  tickets={state.tickets}
                  onCkeckAmount={onCkeckAmount}
                  amountTotal={state.amount}
                  nbTicketCheck={state.nbTicketCheck}
                  goBack={goBack}
                  onChangeNewAmount={onChangeNewAmount}
                  changeInputEmail={changeInputEmail}
                  onCkeckAmountAll={onCkeckAmountAll}
                />
              )}
          </div>
        </section>
      </main>
      <Footer lang={lang} handleLang={handleLang} />
    </>
  );
};

Steps.propTypes = {
  intl: PropTypes.shape({ formatMessage: func }).isRequired,
  fetchWithToken: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onSubmit2: PropTypes.func.isRequired,
  state: PropTypes.shape().isRequired,
  changeInputName: PropTypes.func.isRequired,
  changeInputNumero: PropTypes.func.isRequired,
  changeInputPenalty: PropTypes.func.isRequired,
  changeInputEmail: PropTypes.func.isRequired,
  step: PropTypes.number,
  errorWS: PropTypes.shape(),
  isLoading: PropTypes.bool,
  onCkeckAmount: PropTypes.func.isRequired,
  onChangeNewAmount: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  handleLang: PropTypes.func.isRequired,
  handleStyle: PropTypes.func.isRequired,
  onCkeckAmountAll: PropTypes.func.isRequired,
};

Steps.defaultProps = {
  step: 1,
  isLoading: false,
  errorWS: {},
  fetchWithToken: () => { },
};

export default injectIntl(Steps);
