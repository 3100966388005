/**
 * The connected component of SecurityInformation.
 */
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import SecurityInformation from 'pages/information/SecurityInformation/SecurityInformation';
import hoc from 'pages/information/SecurityInformation/SecurityInformationHOC';


function mapStateToProps(state, props) {
  return {
    lang: props.lang,
    handleLang: props.handleLang,
    handleStyle: props.handleStyle,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(hoc(SecurityInformation)));
