import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { PropTypes, func } from 'prop-types';
import ibulleNom from 'assets/images/ibulle_nom.jpg';
import ibulleNumero from 'assets/images/ibulle_numero.jpg';
import Tooltip, { TooltipButton, TooltipContent } from 'components/Tooltip/Tooltip';
import { validateEmptyValue } from 'services/validators';
// import { Link } from 'react-router-dom';

const formatErrorMessage = (intl, errorJSON) => {
  let message = null;
  // Gestion de l'abandon
  if (errorJSON.state.toLowerCase() === 'abandon') {
    message = intl.formatMessage({ id: 'srt.error.payment.abandon' });
    // Gestion de l'annulation par carte expirant avant fin paiement recurrent
  } else if (errorJSON.state.toLowerCase() === 'cancel_cb_exp') {
    message = intl.formatMessage({ id: 'srt.error.recurring.cb.expiration' });
  } else {
    message = intl.formatMessage({ id: 'srt.payment.error' });
  }
  return message;
};

const handleNeoTranslation = (intl, state) => {
  switch (state.errorWS.neoErrorCode) {
    case 2:
      state.errorWS.label = intl.formatMessage({ id: 'srt.error.no.amount.eligible' });
      break;
    case 3:
      state.errorWS.label = intl.formatMessage({ id: 'srt.error.no.amount' });
      break;
    case 4:
      state.errorWS.label = intl.formatMessage({ id: 'srt.error.unknown.references' });
      break;
    default:
      break;
  }
};

const Step1 = ({
  intl, onSubmit, state, changeInputName, changeInputNumero,
}) => {
  // get client session expired information from session storage
  const clientSessionExpired = sessionStorage.getItem('token.client.expired');

  // get errorPayment from session storage
  const errorPayment = sessionStorage.getItem('errorPayment');
  let errorJSON = null;
  if (errorPayment !== null) {
    // parse JSON from errorPayment
    errorJSON = JSON.parse(errorPayment);
  }

  // className to change list style and hide list title
  // when error is from partner
  let noPuces = '';
  let noTitle = '';
  if (validateEmptyValue(state.errorWS)
    && (state.errorWS.isPartnerError || (state.errorWS.code === 503 && state.errorWS.label === 'srt.error.unavailable'))) {
    noPuces += 'no-puces';
    noTitle += 'no-title';
  }

  return (
    <>
      <h2 className="subtitle-step-name-num">{intl.formatMessage({ id: 'step1.main.subtitle' })}</h2>
      <form onSubmit={onSubmit}>
        {/* client session error */}
        {clientSessionExpired !== null
          && (
            <div
              id="form-error-alert-block"
              className="form-error-block-txt"
              role="alert"
              aria-live="assertive"
              tabIndex="-1"
            >
              {
                clientSessionExpired
              }
            </div>
          )}
        {errorPayment !== null
          && (
            <div
              id="form-error-alert-block"
              className="form-error-block-txt"
              role="alert"
              aria-live="assertive"
              tabIndex="-1"
            >
              {/* if for some reason errorJSON is null, we get a blank page
                * check content in errorJSON before accessing its value
               */}
              {
                formatErrorMessage(intl, errorJSON)
              }
            </div>
          )}
        {(state.errors.length > 0
          || (validateEmptyValue(state.errorWS)))
          && (
            <div
              id="form-error-alert-block"
              className="form-error-block-txt"
              role="alert"
              aria-live="assertive"
              tabIndex="-1"
            >
              <FormattedMessage id="srt.search_error">
                {(text) => (<p className={`form-error-block-title ${noTitle}`}>{text}</p>)}
              </FormattedMessage>
              <ul className={`form-error-block-list ${noPuces}`}>
                {validateEmptyValue(state.errorWS)
                  && (
                    <li key={handleNeoTranslation(intl, state)}>
                      <FormattedMessage id={state.errorWS.label} />
                    </li>
                  )}
                {state.errors.map((e) => (
                  <li key={e.index}><FormattedMessage id={e.labelError} /></li>
                ))}
              </ul>
            </div>
          )}
        <div className="fieldset clearfix">
          <p className="required-fields">
            <sup className="asterisk-required">*</sup>
            <span>{intl.formatMessage({ id: 'step.info.mandatory_field' })}</span>
          </p>
          <span className="fieldset">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="srt-input-block clearfix">
                  <label
                    htmlFor="name-input"
                    className="train-search-label form-label-text"
                  >
                    {intl.formatMessage({ id: 'step1.input.label.name' })}
                    <sup className="asterisk-required">&nbsp;*</sup>
                  </label>
                  <div className="clearfix">
                    <div
                      className={`srt-form-field-wrapper form-field-wrapper-icon-clear form-field-wrapper form-field-wrapper-tooltip ${state.nameError && 'form-error'}`}
                    >
                      <input
                        type="text"
                        name="name-input"
                        id="name-input"
                        className="form-field-with-info form-field"
                        placeholder={intl.formatMessage({ id: 'example' })}
                        title={intl.formatMessage({ id: 'step1.input.label.name' })}
                        value={state.name}
                        onChange={changeInputName}
                      />
                      <button type="button" className="btn-tooltip" aria-expanded="true" aria-controls="train-num-tooltip">
                        <span className="snc-icon-circle-tooltip" aria-hidden="true" />
                      </button>
                      <Tooltip id="train-num-tooltip" intl={intl}>
                        <TooltipButton>
                          <span
                            className="snc-icon-circle-tooltip"
                            aria-hidden="true"
                          />
                        </TooltipButton>
                        <TooltipContent>
                          <img src={ibulleNom} alt="" />
                        </TooltipContent>
                      </Tooltip>
                    </div>
                    {state.nameError
                      && <p className="form-label-error-txt" id="num-srt-input-error">{intl.formatMessage({ id: 'srt.error.name.empty' })}</p>}
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="srt-input-block clearfix">
                  <label
                    htmlFor="num-srt-input"
                    className="train-search-label form-label-text"
                  >
                    {state.penaltyType === 'DPL' ? intl.formatMessage({ id: 'step1.input.label.num.DPL' }) : intl.formatMessage({ id: 'step1.input.label.num.PV' })}
                    <sup className="asterisk-required">&nbsp;*</sup>
                  </label>
                  <div
                    className={`srt-form-field-wrapper form-field-wrapper-icon-clear form-field-wrapper form-field-wrapper-tooltip ${state.numeroError && 'form-error'}`}
                  >
                    <input
                      type="text"
                      name="num-srt-input"
                      id="num-srt-input"
                      className="form-field-with-info form-field"
                      placeholder={intl.formatMessage({ id: 'example' })}
                      title={intl.formatMessage({ id: 'step1.input.label.num' })}
                      value={state.numero}
                      onChange={changeInputNumero}
                    />
                    <button type="button" className="btn-tooltip" aria-expanded="true" aria-controls="train-num-tooltip">
                      <span className="snc-icon-circle-tooltip" aria-hidden="true" />
                    </button>
                    <Tooltip id="train-num-tooltip" intl={intl}>
                      <TooltipButton>
                        <span
                          className="snc-icon-circle-tooltip"
                          aria-hidden="true"
                        />
                      </TooltipButton>
                      <TooltipContent>
                        <img src={ibulleNumero} alt="" />
                      </TooltipContent>
                    </Tooltip>
                  </div>
                  {state.numeroError
                    && <p className="form-label-error-txt" id="num-srt-input-error">{intl.formatMessage({ id: 'srt.error.numero.empty' })}</p>}
                </div>
              </div>
            </div>
          </span>
          <div className="btn-wrapper">
            <div className="btn btn-block btn-srt-back" />
            <button type="submit" className="btn btn-default train-input-btn btn-srt-next" title="Rechercher ce numéro de train" disabled={state.isSearchLoading}>
              {intl.formatMessage({ id: 'step.button.next' })}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

Step1.propTypes = {
  intl: PropTypes.shape({ formatMessage: func }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  state: PropTypes.shape().isRequired,
  changeInputName: PropTypes.func.isRequired,
  changeInputNumero: PropTypes.func.isRequired,
};

export default injectIntl(Step1);
