/**
 * The higher order component responsible for controlling the uic code and submitting the form.
 */
import React, { Component } from 'react';
import { PropTypes, func } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { goToHome } from '../../../utils/url';

export default function hoc(Template) {
  const Resume = class Resume extends Component {
    constructor(props) {
      super(props);
      let transaction = {};
      if (sessionStorage.getItem('resume')) {
        transaction = JSON.parse(sessionStorage.getItem('resume'));
      }
      const langInit = sessionStorage.getItem('lang') ? sessionStorage.getItem('lang') : 'fr';
      props.handleLang(langInit);
      if (!isEmpty(transaction)) {
        this.state = {
          transaction,
        };
      } else {
        this.state = {};
      }
      if (
        props.error
      ) {
        this.hasError = true;
      } else {
        this.shouldFetch = true;
      }
    }

    render() {
      const styleInit = sessionStorage.getItem('style') ? sessionStorage.getItem('style') : 'default';
      this.props.handleStyle(styleInit);
      return (
        <Template
          {...this.props}
          intl={this.props.intl}
          transaction={this.state.transaction}
          onClickFinish={goToHome}
        />
      );
    }
  };

  Resume.propTypes = {
    intl: PropTypes.shape({ formatMessage: func }).isRequired,
    error: PropTypes.shape(),
    clearError: PropTypes.func.isRequired,
    setName: PropTypes.func.isRequired,
    setNumero: PropTypes.func.isRequired,
    setStep: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    tickets: PropTypes.arrayOf().isRequired,
    resetTicket: PropTypes.func.isRequired,
    handleLang: PropTypes.func.isRequired,
    handleStyle: PropTypes.func.isRequired,
  };

  Resume.defaultProps = {
    error: {},
  };

  return Resume;
}
