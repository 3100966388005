/**
 * Action creator generator used for payment check.
 */
import ws from 'services/ws';
import {
  FETCH_CONNEXION, RECEIVE_CONNEXION, RECEIVE_CONNEXION_FAILURE,
} from './ActionTypes';

export const requestPaymentCheck = (
  name,
  numero,
) => ({
  type: FETCH_CONNEXION,
  name,
  numero,
});

export const receivePaymentCheck = (
  name,
  numero,
  res,
) => ({
  type: RECEIVE_CONNEXION,
  name,
  numero,
  res,
});

export const receiveFailurePaymentCheck = (error) => ({
  type: RECEIVE_CONNEXION_FAILURE,
  error,
});

export const fetchPaymentCheck = (
  authorization,
  dispatch,
  endpoint,
  timeout,
) => {
  requestPaymentCheck(
    authorization,
  );
  const lang = sessionStorage.getItem('lang');
  ws(endpoint, timeout).paymentCheck(
    authorization,
    lang,
  ).then(({ res }) => {
    sessionStorage.setItem('resume', JSON.stringify(res));
    window.location.replace('/resume');
  }, (error) => {
    // check if client token is expired here
    const parseError = error.response.body;
    if (parseError
      && parseError.error.code === 401
      && parseError.error.label === 'UNAUTHORIZED'
    ) {
      // client token expired
      sessionStorage.setItem('token.client.expired', 'Session expirée');
      window.location.replace('/');
    } else {
      // token not expired, different error
      dispatch(receiveFailurePaymentCheck(error));
      // store entire error for usage later
      if (parseError.state === 'REFUSED') {
        sessionStorage.setItem('resume', JSON.stringify(parseError));
        window.location.replace('/resume');
      } else {
        sessionStorage.setItem('errorPayment', JSON.stringify(parseError));
        window.location.replace('/');
      }
    }
  });
};
