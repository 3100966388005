import React from 'react';
import PropTypes from 'prop-types';
import frenchFlag from 'assets/images/french.svg';
import englishFlag from 'assets/images/english.svg';
import deutschFlag from 'assets/images/deutsch.svg';
import { FormattedMessage } from 'react-intl';

class Footer extends React.PureComponent {
  onChangeLanguage = (e) => {
    e.preventDefault();
    this.props.handleLang(e.target.id);
  };

  render() {
    const { lang } = this.props;
    let flagToDisplay = frenchFlag;
    if (this.props.lang === 'en') {
      flagToDisplay = englishFlag;
    } else if (this.props.lang === 'de') {
      flagToDisplay = deutschFlag;
    }
    return (
      <footer id="footer" className="footer" role="contentinfo">
        <div className="container">
          <div id="footer-quick-links" className="footer-links">
            <ul className="footer-list-links">
              <li>
                <a href={`/${lang}/mentions-legales`}>
                  <FormattedMessage id="legal.information.title" />
                </a>
              </li>
              <li>
                <a href={`/${lang}/contact`}>
                  <FormattedMessage id="contact.title" />
                </a>
              </li>
              <li>
                <a href={`/${lang}/infos-securite`}>
                  <FormattedMessage id="security.information.title" />
                </a>
              </li>
              <li>
                <a href={`/${lang}/dispositions-applicables`}>
                  <FormattedMessage id="payment.method.title" />
                </a>
              </li>
            </ul>
            <ul className="footer-list-langues">
              <li>
                <img src={flagToDisplay} alt="" width="26" height="26" className="footer-flag" />
                <button type="button" lang="fr" title="Français" onClick={this.onChangeLanguage} className={`popin-languages-button ${lang === 'fr' ? 'active' : ''}`} id="fr"><span id="fr">Fr</span></button>
              </li>
              <li>
                <button type="button" lang="en" title="English" onClick={this.onChangeLanguage} className={`popin-languages-button ${lang === 'en' ? 'active' : ''}`} id="en"><span id="en">En</span></button>
              </li>
              <li>
                <button type="button" lang="de" title="Deutsch" onClick={this.onChangeLanguage} className={`popin-languages-button ${lang === 'de' ? 'active' : ''}`} id="de"><span id="de">De</span></button>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  lang: PropTypes.string,
  handleLang: PropTypes.func.isRequired,
};

Footer.defaultProps = {
  lang: 'fr',
};

export default Footer;
